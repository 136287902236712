import $ from 'jquery';
window.$ = window.jQuery = $;

import jqueryValidate from 'jquery-validation';

import { Util } from 'bootstrap';

var EmailRegex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
$.validator.addMethod(
    "myEmail",
    function(value, element) {
        var re = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return this.optional(element) || re.test(value);
    },
    "Please enter a valid email address."
);

$.validator.addMethod(
    "phone",
    function(value, element) {
        var re =/^[0-9-+]+$/;
        return this.optional(element) || re.test(value);
    },
    "Please enter a valid phone number."
);

jQuery(function(){
    $("#contact-form").validate({
        rules: {
            email: {
                required: true,
                email: true,
                myEmail:true,
            },
            phone: {
                required: true,
                phone:true,
            },
            name: {
                required: true,
                minlength: 3
            },
            message: {
                required: false,
                // minlength: 10
            },
        },
        submitHandler: function (form) {
    
    
            $('#contact-form .submit-btn').attr("disabled", true);;
            
            $.ajax({
                type: "POST",
                url: "formfiles/submit.php",
                data: $(form).serialize(),
                success: function (res) {
                    let message = res.message;
                   
                    if(res.code == 200){
                        $('#res-meesage').html('<span class="success">'+message+'</span>');
                      form.reset();
               
                    }else if(res.code == 401){
                        $('#res-meesage').html('<span class="error">'+message+'</span>');
                    }else{
                        $('#res-meesage').html('<span class="error">'+message+'</span>');
                    }
                    $('#contact-form .submit-btn').attr("disabled", false);;
                
                },
                error:function(){
                    $('#res-meesage').html('<span class="error">Error</span>'); 
                    $('#contact-form .submit-btn').attr("disabled", false);
                }
            });

            setInterval(function(){ $('#res-meesage').html('')},3000);


            return false; 
    
    
           
        }
    });
});
